import axiosInstance from "./Api";

export default {

  async getStripeLink() {

    let response = await axiosInstance().get(`/stripe-connect/get-link`);

    return response;
  },

  async verifyStripeAccount() {

    let response = await axiosInstance().post(`/stripe-connect/verify-account`);

    return response;
  },

  async getStripeAccountDetails() {

    let response = await axiosInstance().get(`/stripe-connect/acc-details`);

    return response;
  },

  async getStripeBankAccountDetails() {

    let response = await axiosInstance().post(`/stripe-connect/fetch-acc-details`);

    return response;
  },

};
