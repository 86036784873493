<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card>
        <h4><b>Personal Details</b></h4>
        <hr />

        <!-- form -->
        <b-form>
          <b-row>
            <b-col sm="6">
              <b-form-group label="First Name" label-for="first_name">
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="form.first_name"
                    placeholder="First Name"
                    name="username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Last Name" label-for="account-lname">
                <b-form-input
                  v-model="form.last_name"
                  name="name"
                  placeholder="Last Name"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Mobile" label-for="account-mobile">
                <b-row>
                  <b-col md="4">
                    <b-form-select v-model="form.phone_code">
                      <b-form-select-option
                        v-for="(item, index) in countryCode"
                        :key="index"
                        :value="item.code"
                        :disabled="item.code === 'disabled'"
                      >
                        {{ item.name }}
                        {{
                          item.code === "disabled" ? "" : "(" + item.code + ")"
                        }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-col>
                  <b-col md="8">
                    <b-form-input
                      v-model="form.phone_number"
                      name="company"
                      placeholder="Mobile"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="E-mail" label-for="account-e-mail">
                <b-form-input
                  disabled
                  name="email"
                  v-model="form.email"
                  placeholder="Email"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-for="example-datepicker" label="Birth Date">
                <b-form-datepicker v-model="form.dob" :max="max" locale="en" />
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click="onSubmit"
              >
                Save changes
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </validation-observer>
  </div>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BInputGroupPrepend,
  BInputGroup,
  BFormSelect,
  BFormSelectOption,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.us";
import { country_code } from "./countryCode";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import UserManagementServices from "@/apiServices/UserManagementServices";
import storageService from "@/apiServices/storageService";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BInputGroupPrepend,
    BInputGroup,
    BFormSelect,
    BFormSelectOption,

    flatPickr,
    Cleave,
    vSelect,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);

    return {
      countryCode: country_code,

      form: {
        first_name: "",
        last_name: "",
        phone_code: "",
        phone_number: "",
        dob: "",
      },

      max: maxDate,
    };
  },
  methods: {
    onSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          UserManagementServices.saveSettingsPersonal(this.form)
            .then((response) => {
              if (response.data.status) {
                localStorage.setItem("token", response.data.data.token);
                localStorage.setItem(
                  "userInfo",
                  JSON.stringify(response.data.data.user)
                );

                this.$store.commit(
                  "user/SET_USER_DETAILS",
                  response.data.data.user
                );

                this.Toast(response.data.message, "ThumbsUpIcon", "success");
              } else {
                this.Toast(response.data.message, "AlertTriangleIcon", "error");
              }
            })
            .catch((error) => {
              this.errorMessage = error.message;
              console.error("There was an error!", error);
            });
        }
      });
    },

    Toast(message, icon, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: icon,
          variant: type,
        },
      });
    },
  },

  beforeMount() {
    this.form.first_name =
      this.$store.getters["user/getUserDetails"].user_firstname;
    this.form.last_name =
      this.$store.getters["user/getUserDetails"].user_lastname;
    this.form.phone_number =
      this.$store.getters["user/getUserDetails"].user_mobile;
    this.form.email = this.$store.getters["user/getUserDetails"].user_email;
    this.form.dob = this.$store.getters["user/getUserDetails"].user_dob;

    const getPhoneCode =
      this.$store.getters["user/getUserDetails"].user_phone_code;
    let selectedPhoneCode = this.countryCode.filter((x) => {
      return x.code == getPhoneCode;
    });

    this.form.phone_code =
      selectedPhoneCode.length > 0 ? selectedPhoneCode[0].code : "";
  },
};
</script>
